<template>
  <section :class="$style.clientCategories">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectClientCategory"
          @handleFindItems="handleFindClientCategories"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <div :class="$style.addCategory">
        <el-input
          :class="$style.addInput"
          v-model="newCategory"
          placeholder="Введите новую категорию..."
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-plus"
            @click="createCategory()"
          />
        </el-input>
      </div>
    </div>
    <el-table :data="clientCategories" stripe>
      <el-table-column width="240" label="Приоритет">
        <template slot-scope="scope">
          <el-input-number
            :min="0"
            :max="999999999"
            v-model="scope.row.orderField"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="Название">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.name"
            @input="handleNameInput(scope.$index)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Название в URL">
        <template slot-scope="scope">
          <el-input v-model="scope.row.slug"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            editModal
            name="trash"
            @edit="update(scope.row)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import { transliterate } from '@/helpers/slug'

export default {
  components: {
    ActionButtons,
    Autocomplete,
  },
  data() {
    return {
      clientCategories: [],
      limit: 20,
      page: 1,
      total: 10,
      search: '',
      newCategory: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async createCategory() {
      const result =
        await delivery.ContentServiceCore.ClientCategoriesActions.create({
          name: this.newCategory,
          slug: transliterate(this.newCategory),
        })

      if (!result.error) {
        this.$message({
          message: 'Категория успешно создана',
          type: 'success',
        })
        this.newCategory = ''
        this.getList()
      }
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }
      const { value, error } =
        await delivery.ContentServiceCore.ClientCategoriesActions.getList(query)

      if (error) {
        loading.close()
        return
      }

      this.clientCategories = value.data
      this.total = value.meta.count
      loading.close()
    },
    handleNameInput(idx) {
      this.clientCategories[idx].slug = transliterate(
        this.clientCategories[idx].name,
      )
    },
    async handleFindClientCategories() {
      await this.getList()
    },
    handleSelectClientCategory(selectedUser) {
      this.search = selectedUser.name
      this.handleFindClientCategories()
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.ClientCategoriesActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async update(data) {
      const res =
        await delivery.ContentServiceCore.ClientCategoriesActions.update(
          data.id,
          data,
        )
      if (!res.error) {
        this.$message({
          message: 'Категория успешно отредактирована',
          type: 'success',
        })
        this.getList()
      }
    },
    async remove(data) {
      const confirmed = confirm(`Вы точно хотите удалить ${data.name}?`)

      if (confirmed) {
        const res =
          await delivery.ContentServiceCore.ClientCategoriesActions.delete(
            data.id,
          )
        if (!res.error) {
          this.$message({
            message: 'Категория успешно удалена',
            type: 'success',
          })
          this.getList()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.clientCategories {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .addCategory {
      display: flex;
      .addInput {
        width: 50rem;
        margin-right: 0.625rem;
      }
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
